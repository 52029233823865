import mail from "./assets/a.png";
import fbIcon from "./assets/fb.png";
import gitHubIcon from "./assets/git.png";
import instagramIcon from "./assets/ig.png";
import mediumIcon from "./assets/medium.png";
import nftIcon from "./assets/nft.png";
import telegramIcon from "./assets/telegram.png";
import xIcon from "./assets/x.png";
import youtubeIcon from "./assets/yt.png";

const _links = [
  { href: "https://x.com/btaftoken", src: xIcon },
  { href: "https://t.me/taftoken", src: telegramIcon },
  { href: "https://medium.com/@TAFtoken", src: mediumIcon },
  { href: "https://www.youtube.com/@btaftoken/videos", src: youtubeIcon },
  { href: "https://www.facebook.com/btaftoken.io", src: fbIcon },
  { href: "https://bitcointaf.com/btaf-token-nfts/", src: nftIcon },
  { href: "https://github.com/BTafPremiere", src: gitHubIcon },
  { href: "https://www.instagram.com/Taftoken/", src: instagramIcon },
  { href: "mailto://btaftoken@bitcointaf.com", src: mail },
];

export default _links;
