import { Typography } from "@mui/material";

const ResourceItem = ({ href, label }) => {
    return (
        <Typography
            variant="subtitle2"
            fontWeight={700}
            className="footer-menus"
        >
            <a href={href} target="_blank">
                {label}
            </a>
        </Typography>
    );
};

export default ResourceItem;
