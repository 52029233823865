import { Stack, Typography } from "@mui/material";
import React from "react";

const Title = ({ title = "", subTitle = "", image }) => {
  return (
    <Stack direction="row" justifyContent="center" alignItems="center">
      <Stack sx={{ width: "100%" }}>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Typography
            variant="h6"
            sx={{ color: "#fff", textAlign: "center", fontWeight: "700" }}
          >
            {title}
          </Typography>
          <img src={image} width="50px" />
        </Stack>
        <Typography
          variant="subtitle2"
          sx={{ textAlign: "center", color: "#989898" }}
        >
          {subTitle}
        </Typography>
      </Stack>

      {/* <img src={image} width="100px" /> */}
    </Stack>
  );
};

export default Title;
