import { enqueueSnackbar } from "notistack";
import { loadAddress, useAddressDialog } from "src/store/address-dialog";
import { init, useMetaMask } from "src/store/metamask";
import connect from "./connect";
import getAddresses from "./get-address";

const useSetup = () => {
  const [_, dispatch] = useAddressDialog();
  const [__, metamaskDispatch] = useMetaMask();

  async function setup() {
    if (window.ethereum) {
      try {
        const addresses = await getAddresses();
        // const connectedData = await connect(addresses[0]);
        // metamaskDispatch(init(connectedData));
        if (addresses.length > 1) {
          dispatch(loadAddress(addresses));
          return true;
        } else {
          const connectedData = await connect(addresses[0]);
          metamaskDispatch(init(connectedData));
          return true;
        }
      } catch (error) {
        console.log("User denied account access to MetaMask.");
        enqueueSnackbar("User denied account access to MetaMask.", {
          variant: "error",
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
        });
        return false;
        // Show an error message or instructions to enable MetaMask
      }
    }

    // else if (window.web3) {
    //   // Use Mist/MetaMask's provider
    //   // const web3 = new Web3(window.web3.currentProvider);
    //   return connect();
    //   // Further code to interact with MetaMask using Web3.js
    // }
    // // Non-dapp browsers...
    // else {
    //   enqueueSnackbar("MetaMask is not installed or not enabled.", {
    //     variant: "error",
    //     anchorOrigin: {
    //       horizontal: "right",
    //       vertical: "top",
    //     },
    //   });
    //   // console.log("MetaMask is not installed or not enabled.");
    //   // Show an error message or instructions to install MetaMask
    // }
  }
  return setup;
};

export default useSetup;
