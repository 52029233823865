import { Box, Button, Grid, Stack, Tooltip } from "@mui/material";
import { useRef } from "react";
import { Link } from "react-router-dom";
import IntroSvg from "src/assets/intro.svg";
import LogoPng from "src/assets/logo-png.png";
import Footer from "src/components/footer";
import SocialIcons from "src/components/social-icons";

import "../style.css";
import "../style.scss";
import BtafStake from "./components/btaf-stake";
import Terms from "./components/terms";

const Stake = () => {
    const viewRef = useRef(null);

    return (
        <>
            <Box className="main">
                <Box className="section banner banner-section">
                    <Box className="container">
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Box
                                    className="banner-inner"
                                    sx={{ marginTop: "5rem" }}
                                >
                                    <h1 className="heading-xl">
                                        Staking made simple via BTAFStake
                                    </h1>

                                    <Stack direction="row" spacing={2}>
                                        <Button
                                            variant="contained"
                                            sx={{
                                                textTransform: "none",
                                                borderRadius: "1rem",
                                                fontWeight: "800",
                                                padding: "1rem",
                                            }}
                                            className="stake-token-btn"
                                            size="large"
                                            onClick={() => {
                                                viewRef.current.scrollIntoView({
                                                    behavior: "smooth",
                                                    block: "start",
                                                });
                                            }}
                                        >
                                            Stake your Tokens
                                        </Button>
                                        <Tooltip title="IPO Members - Use your BitcoinTAF.com login details.">
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    textTransform: "none",
                                                    borderRadius: "1rem",
                                                    fontWeight: "800",
                                                    padding: "1rem",
                                                }}
                                                className="stake-token-btn"
                                                size="large"
                                                LinkComponent={Link}
                                                to={"/private-stake"}
                                            >
                                                IPO Members Login
                                            </Button>
                                        </Tooltip>
                                    </Stack>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ position: "relative" }}>
                                    <Box
                                        sx={{
                                            position: "absolute",
                                            left: "30%",
                                            right: "20%",
                                            zIndex: 1,
                                            top: "30%",
                                        }}
                                    >
                                        <img
                                            className="banner-image"
                                            src={LogoPng}
                                        />
                                    </Box>
                                    <img
                                        className="banner-image animation-svg"
                                        src={IntroSvg}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <SocialIcons />
                </Box>
            </Box>
            <Box ref={viewRef}>
                <BtafStake />
            </Box>
            <Terms />
            <Footer />
        </>
    );
};

export default Stake;
