import connectToChain from "./connectToChain";
import connectToContracts from "./connectToContracts";
import extractTimeRemaining from "./extract-time-remaining";

const connect = async (address) => {
    const [signer, signerAddress] = await connectToChain(address);

    const [
        tokenContract,
        stakingContract,
        stakingContract_35,
        stakingContract_new,
        stakingContract_selfMaturing,
        stakingContractSelfMaturingPrivate,
    ] = await connectToContracts(signer);
    const timeRemaining = await extractTimeRemaining(stakingContract);
    const timeRemaining_35 = await extractTimeRemaining(stakingContract_35);
    const timeRemaining_new = await extractTimeRemaining(stakingContract_new);
    const private_stake_timeRemaining = await extractTimeRemaining(
        stakingContractSelfMaturingPrivate
    );

    return {
        signerAddress,
        tokenContract,
        stakingContract,
        timeRemaining,
        timeRemaining_35,
        stakingContract_35,
        timeRemaining_new,
        stakingContract_new,
        stakingContract_selfMaturing,
        private_stake: stakingContractSelfMaturingPrivate,
        private_stake_timeRemaining,
    };
};

export default connect;
