import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DogLeg from "src/assets/dog-leg.png";
import LogoSvg from "src/assets/logo.png";
import MetaMaskLogo from "src/assets/metamask.png";
import PanImg from "src/assets/pan.png";
import Iconify from "src/components/Iconify";
import { USE_BASE_URL } from "src/config";
import useSetup from "src/cryptoUtilities/useSetup";
import { useMetaMask } from "src/store/metamask";
import Web3 from "web3";

export default function TemporaryDrawer() {
  const [state, setState] = useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [openConnect, setOpenConnect] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [storeData] = useMetaMask();

  const setup = useSetup();
  const onConnected = async () => {
    const data = await setup();
    if (data) {
      setOpenConnect(false);
    }
  };

  useEffect(() => {
    async function checkForMetaMask() {
      // Modern dapp browsers...
      if (window.ethereum) {
        try {
          // Request account access if needed
          await window.ethereum.request({ method: "eth_requestAccounts" });
          console.log("MetaMask is installed and enabled.");
          // Further code to interact with MetaMask using Web3.js
        } catch (error) {
          console.log("User denied account access to MetaMask.");
          // Show an error message or instructions to enable MetaMask
        }
      }
      // Legacy dapp browsers...
      else if (window.web3) {
        // Use Mist/MetaMask's provider
        const web3 = new Web3(window.web3.currentProvider);
        console.log("MetaMask is installed and enabled.");
        // Further code to interact with MetaMask using Web3.js
      }
      // Non-dapp browsers...
      else {
        console.log("MetaMask is not installed or not enabled.");
        // Show an error message or instructions to install MetaMask
      }
    }

    checkForMetaMask();
  }, []);

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        backgroundColor: "#1a1b1e",
        height: "100%",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem disablePadding>
          <Box>
            <ul>
              <li
                style={{
                  display: "flex",
                  color: "#fff",
                  fontWeight: 800,
                  alignItems: "center",
                  padding: "0.5rem",
                }}
              >
                <img
                  src={LogoSvg}
                  width={30}
                  style={{ objectFit: "contain" }}
                />
                <span className="mobile-size-para" style={{ fontSize: "18px" }}>
                  BTAF token
                </span>
                <img
                  src={DogLeg}
                  style={{
                    width: "30px",
                    marginLeft: "5px",
                  }}
                />
              </li>
            </ul>
          </Box>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ul className="mobile-list-ul">
            <li>
              <Link to="/key" className="menu-link">
                <Button
                  startIcon={<Iconify icon="ph:globe-light" />}
                  variant="text"
                >
                  BTAFkey
                </Button>
              </Link>
            </li>
            <li>
              <Button
                startIcon={<Iconify icon="fluent:people-team-24-filled" />}
                variant="text"
                href={`${USE_BASE_URL}our-team`}
                sx={{
                  color: "#fff",
                  fontWeight: 700,
                  textTransform: "capitalize",
                }}
              >
                BTAF Team
              </Button>
            </li>
            <li>
              <Link to="/stake" className="menu-link">
                <Button
                  startIcon={<Iconify icon="ic:outline-star-outline" />}
                  variant="text"
                >
                  BTAFStake
                </Button>
              </Link>
            </li>
            <li>
              <Link to="/pay" className="menu-link">
                <Button
                  startIcon={<Iconify icon="ph:telegram-logo" />}
                  variant="text"
                >
                  BTAFpay
                </Button>
              </Link>
            </li>
            <li>
              <Button
                startIcon={<Iconify icon="mdi:twitter" />}
                variant="text"
                href="https://twitter.com/btaftoken"
                target="_blank"
                sx={{ color: "#fff", textTransform: "capitalize" }}
              >
                Twitter
              </Button>
            </li>
            <li>
              <Button
                startIcon={<Iconify icon="uil:telegram-alt" />}
                variant="text"
                href="https://t.me/taftoken"
                target="_blank"
                sx={{ color: "#fff", textTransform: "capitalize" }}
              >
                Telegram
              </Button>
            </li>
            <li>
              <Button
                startIcon={<Iconify icon="simple-icons:medium" />}
                variant="text"
                href="https://medium.com/@TAFtoken"
                target="_blank"
                sx={{ color: "#fff", textTransform: "capitalize" }}
              >
                Medium
              </Button>
            </li>
            <li>
              <Button
                startIcon={<Iconify icon="carbon:logo-youtube" />}
                variant="text"
                href="https://www.youtube.com/channel/UCQ_UZw1Av5cSsQ6BB9Wq63g"
                target="_blank"
                sx={{ color: "#fff", textTransform: "capitalize" }}
              >
                Youtube
              </Button>
            </li>
            <li>
              <Button
                startIcon={<Iconify icon="ic:baseline-facebook" />}
                variant="text"
                href="https://www.facebook.com/btaftoken.io"
                target="_blank"
                sx={{ color: "#fff", textTransform: "capitalize" }}
              >
                Facebook
              </Button>
            </li>

            <li>
              <Button
                variant="contained"
                className="connect-btn"
                size="small"
                onClick={() => {
                  setOpenConnect(true);
                }}
              >
                {storeData.signerAddress ? "Connected" : "Connect"}
              </Button>
            </li>
            <li>
              <Button variant="text" size="small">
                Buy $BTAF
              </Button>
            </li>
            <li>
              <Button
                startIcon={<img src={PanImg} style={{ marginRight: "10px" }} />}
                variant="contained"
                href="https://pancakeswap.finance/swap?outputCurrency=0xcAE3d82D63e2b0094bc959752993D3D3743B5D08"
                target="_blank"
                className="buy-taf-btn"
                sx={{
                  fontSize: "12px",
                  textTransform: "capitalize",
                  borderRadius: "2rem",
                }}
              >
                PancakeSwap
              </Button>
            </li>
          </ul>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <div>
        {["left"].map((anchor) => (
          <React.Fragment key={anchor}>
            {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
            <div
              className="burger"
              id="burger"
              onClick={toggleDrawer(anchor, true)}
            >
              <span className="burger-line"></span>
              <span className="burger-line"></span>
              <span className="burger-line"></span>
            </div>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </div>
      <Dialog
        open={openConnect}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#212429",
            borderRadius: "2rem",
          },
        }}
      >
        <DialogTitle id="responsive-dialog-title" sx={{ color: "#fff", mt: 5 }}>
          <Typography variant="h5" sx={{ fontWeight: 800 }}>
            Connect Wallet
          </Typography>
        </DialogTitle>

        {/* Connect Wallet  */}
        <DialogContent>
          <Button
            variant="outlined"
            onClick={onConnected}
            sx={{
              borderRadius: "3rem",
              textTransform: "capitalize",
              mt: 1,
              fontWeight: 600,
              color: "#fff",
            }}
            fullWidth
            size="large"
            startIcon={
              <img src={MetaMaskLogo} style={{ margin: "0px 20px 0px 0px" }} />
            }
          >
            Meta Mask
          </Button>
          <Typography
            variant="subtitle2"
            fontWeight={500}
            style={{ color: "#ccc" }}
            mt={2}
            mb={2}
          >
            You don't have any crypto wallet?
          </Typography>
          <Button
            variant="contained"
            sx={{
              borderRadius: "1rem",
              textTransform: "capitalize",
              mt: 1,
              fontWeight: 600,
            }}
            className="connect-btn"
            fullWidth
            size="large"
            href="https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain"
            target="_blank"
          >
            Learn how to connect
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}
