import { Box, Typography } from "@mui/material";
import { USE_BASE_URL } from "src/config";
import ResourceItem from "./components/resource-item";

const Resources = () => {
    return (
        <Box sx={{ textAlign: "left" }}>
            <Typography
                sx={{
                    color: "#fff",
                    fontSize: "13px",
                    fontWeight: "600",
                    letterSpacing: "1px",
                    mb: 2,
                }}
            >
                RESOURCES
            </Typography>
            <ResourceItem href={`${USE_BASE_URL}/about-us/`} label="About us" />
            <ResourceItem
                href="https://btaftoken.io/files/whitePaper.pdf"
                label="Whitepaper"
            />

            <ResourceItem href="https://medium.com/@TAFtoken" label="Blog" />
            <ResourceItem href="https://help.btaftoken.io" label="Support" />
            <ResourceItem href={`${USE_BASE_URL}`} label="Media Kit" />
        </Box>
    );
};

export default Resources;
