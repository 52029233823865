import { createContext, useContext, useReducer } from "react";

const context = createContext();

const LOAD_ADDRESS = "LOAD_ADDRESS";
const CLEAR = "CLEAR";

export const loadAddress = (payload) => ({ payload, type: LOAD_ADDRESS });
export const clearAddress = () => ({ payload: null, type: CLEAR });

const initialState = null;

const reducer = (state = null, { payload, type }) => {
  switch (type) {
    case LOAD_ADDRESS: {
      return payload;
    }
    case CLEAR: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const AddressDialogContext = ({ children }) => {
  const [data, dispatch] = useReducer(reducer, initialState);

  return (
    <context.Provider value={[data, dispatch]}>{children}</context.Provider>
  );
};

export const useAddressDialog = () => useContext(context);

export default AddressDialogContext;
