import { Box, Stack } from "@mui/material";
import Map from "../map";
import _links from "./_links";

const SocialIcons = () => {
  return (
    <Box className="container banner-column mobile-dsplay-none">
      <Box
        className="banner-links"
        sx={{
          pt: 1,
          pb: 1,
          p: 2,
        }}
      >
        <Stack spacing={2}>
          <Map list={_links} render={(link) => <IconItem {...link} />} />
        </Stack>
      </Box>
    </Box>
  );
};

const IconItem = ({ href, src }) => {
  return (
    <a href={href} target="_blank">
      <img width={30} height={20} src={src} alt={href} />
    </a>
  );
};

export default SocialIcons;
