import {
    CONTRACT_35_APY,
    CONTRACT_NEW_APY,
    CONTRACT_SELF_MATURING_APY,
    CONTRACT_SELF_MATURING_APY_PRIVATE,
    USE_CONTRACT,
} from "../config";

const getChain = () => {
    // let selectedContract = window.location.href.split("?contract=")[1];
    // console.log(USE_CONTRACT);
    return {
        local: {
            chainId: "0x539",
            chainName: "Localhost 8545",
            nativeCurrency: {
                name: "Local Ether",
                symbol: "ETH",
                decimals: 18,
            },
            rpcUrls: ["http://localhost:8545"],
            tokenContractAddress: "0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9",
            stakingContractAddress: USE_CONTRACT,
        },
        bsctest: {
            chainId: "0x61",
            chainName: "Binance Smart Chain Testnet",
            nativeCurrency: {
                name: "Binance Coin",
                symbol: "BNB",
                decimals: 18,
            },
            rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
            blockExplorerUrls: ["https://testnet.bscscan.com"],
            // These are the addresses on the blockchain that the contracts reside at
            tokenContractAddress: "0xf2B7Ff29F36cD0b170BD0903d99dFEc518fbE94f",
            stakingContractAddress: USE_CONTRACT,
            stakingContractAddress_35_apy: CONTRACT_35_APY,
            stakingContractAddress_new_apy: CONTRACT_NEW_APY,
            stakingContract_selfMaturing_apy: CONTRACT_SELF_MATURING_APY,
            stakingContract_selfMaturing_apy_private:
                CONTRACT_SELF_MATURING_APY_PRIVATE,
        },
        bsc: {
            chainId: "0x38", //56 is acutal chain id
            chainName: "Binance Smart Chain",
            nativeCurrency: {
                name: "Binance Coin",
                symbol: "BNB",
                decimals: 18,
            },
            rpcUrls: ["https://bsc-dataseed.binance.org/"],
            tokenContractAddress: "0xcAE3d82D63e2b0094bc959752993D3D3743B5D08",
            stakingContractAddress:
                "0xF79d77182D1ECdCD963e8Ce410EdD0f9a4aa8740",
            stakingContractAddress_35_apy: CONTRACT_35_APY,
            stakingContractAddress_new_apy: CONTRACT_NEW_APY,
            stakingContract_selfMaturing_apy: CONTRACT_SELF_MATURING_APY,
            stakingContract_selfMaturing_apy_private:
                CONTRACT_SELF_MATURING_APY_PRIVATE,
            blockExplorerUrls: ["https://bscscan.com"],
        },
    };
};

export default getChain;
