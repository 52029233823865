import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";

const Claim = ({ open, onClose, claimReward, reward }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiPaper-root": {
          backgroundColor: "#212429",
          borderRadius: "2rem",
        },
      }}
      fullWidth
      maxWidth="sm"
    >
      <Box className="modal-header-claim">
        <DialogTitle
          id="alert-dialog-title"
          sx={{ fontWeight: 600, color: "#fff", mt: 5 }}
        >
          Claim Rewards
        </DialogTitle>
      </Box>
      <DialogContent>
        <Typography variant="subtitle2" sx={{ color: "#fff" }}>
          Available Amount&nbsp;:
          <span style={{ fontWeight: 800, fontSize: "15px" }}>{reward}</span>
        </Typography>
        <Typography
          variant="subtitle2"
          mt={2}
          sx={{ fontSize: "0.775rem", color: "#ccc", fontWeight: 700 }}
        >
          You can only claim the full number of available rewards. There is no
          fee for claiming rewards. <br />
          If you do not wish to proceed , select the ‘Cancel’ button
        </Typography>
        <DialogActions sx={{ marginBottom: "2rem" }}>
          <Button
            onClick={onClose}
            autoFocus
            variant="outlined"
            sx={{
              borderRadius: "1rem",
              textTransform: "capitalize",
              mt: 1,
              fontWeight: 600,
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={claimReward}
            variant="contained"
            sx={{
              borderRadius: "1rem",
              textTransform: "capitalize",
              mt: 1,
              fontWeight: 600,
            }}
            className="claim-now-btn"
          >
            Claim Now
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default Claim;
