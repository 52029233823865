import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import getFeePercent from "src/cryptoUtilities/calculateFee";
import getDailyReward from "src/cryptoUtilities/calculateReward";

const CalculateReward = ({
  open,
  onClose,
  dayData,
  feePercent,
  dailyReturn,
}) => {
  const [amount, setAmount] = useState("");
  const [fee, setFee] = useState("");
  const [actualToken, setActualTokens] = useState("");
  const [calculatedValue, setcalculatedValue] = useState("");
  const calculateReward = getDailyReward(dailyReturn);
  const calculateFee = getFeePercent(feePercent);
  useEffect(() => {
    if (amount) {
      const parsedAmount = parseFloat(amount);
      setFee(calculateFee(parsedAmount));
    } else {
      setFee("");
      setActualTokens("");
    }
  }, [amount]);

  useEffect(() => {
    if (amount) {
      const parsedAmount = parseFloat(amount);
      setFee(calculateFee(parsedAmount));
    } else {
      setFee("");
      setActualTokens("");
    }
  }, [amount]);
  useEffect(() => {
    if (fee) {
      setActualTokens(amount - fee);
    }
  }, [fee]);

  useEffect(() => {
    if (!open) {
      setAmount("");
      setFee("");
      setActualTokens("");
      setcalculatedValue("");
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiPaper-root": {
          backgroundColor: "#212429",
          borderRadius: "2rem",
        },
      }}
      fullWidth
      maxWidth="sm"
    >
      <Box className="modal-header-stake">
        <DialogTitle
          id="responsive-dialog-title"
          sx={{ fontWeight: 600, color: "#fff", mt: 5 }}
        >
          Calculate Rewards
        </DialogTitle>
      </Box>
      <DialogContent>
        <Box
          sx={{
            display: "grid",
            rowGap: 2,
            columnGap: 2,
            marginTop: 1,
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              sm: "repeat(1, 1fr)",
            },
            "& .MuiFormControl-root": {
              border: "solid 1px #cccccc2b",
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#ffffff00",
              },
            },
          }}
        >
          <TextField
            value={amount}
            label="Number of Tokens to Stake:"
            id="custom-css-outlined-input"
            type="number"
            onChange={(e) => {
              const { value } = e.target;
              if (value) {
                const intValue = parseFloat(value);
                setAmount(intValue);
                setcalculatedValue(calculateReward(intValue, dayData));
              } else {
                setAmount("");
                setcalculatedValue("");
              }
            }}
            sx={{
              borderRadius: "1rem",
              "& .MuiInputLabel-root": {
                color: "#fff",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderRadius: "1rem",
                },
              },
              "& .MuiInputBase-input": {
                color: "#fff",
              },
            }}
          />
          <TextField
            value={fee}
            label={`${feePercent * 100}% Fee:`}
            id="custom-css-outlined-input"
            type="number"
            sx={{
              borderRadius: "1rem",
              "& .MuiInputLabel-root": {
                color: "#fff",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderRadius: "1rem",
                },
              },
              "& .MuiInputBase-input": {
                color: "#fff",
              },
            }}
          />
          <TextField
            value={actualToken}
            label="Actual Number That Will Be Staked:"
            id="custom-css-outlined-input"
            type="number"
            sx={{
              borderRadius: "1rem",
              "& .MuiInputLabel-root": {
                color: "#fff",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderRadius: "1rem",
                },
              },
              "& .MuiInputBase-input": {
                color: "#fff",
              },
            }}
          />
        </Box>
        <Typography
          variant="subtitle2"
          fontWeight={700}
          style={{ color: "#ccc" }}
          mt={5}
        >
          Approximate Number of Rewards Generated: <b>{calculatedValue}</b>
        </Typography>
        <Typography
          variant="subtitle2"
          mt={2}
          sx={{ fontSize: "0.775rem", color: "#ccc", fontWeight: 700 }}
        >
          Use this calculator to determine the approximate number of rewards
          generated if you stake tokens now and leave them in the contract until
          after the maturation date.
        </Typography>
        <DialogActions>
          <Button
            onClick={onClose}
            sx={{
              borderRadius: "1rem",
              textTransform: "capitalize",
              mt: 1,
              fontWeight: 600,
              textAlign: "left",
            }}
            autoFocus
            variant="outlined"
          >
            Close
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default CalculateReward;
