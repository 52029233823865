import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import Ternary from "src/components/ternary";
import getFeeCalculator from "src/cryptoUtilities/calculateFee";

const useStakeInfo = (feePercent) => {
  const [amount, setAmount] = useState("");
  const calculateFee = getFeeCalculator(feePercent);
  const [fee, setFee] = useState("");
  const [actualToken, setActualTokens] = useState("");

  useEffect(() => {
    if (amount) {
      const parsedAmount = parseFloat(amount);
      setFee(calculateFee(parsedAmount));
    } else {
      setFee("");
      setActualTokens("");
    }
  }, [amount]);

  useEffect(() => {
    if (amount) {
      const parsedAmount = parseFloat(amount);
      setFee(calculateFee(parsedAmount));
    } else {
      setFee("");
      setActualTokens("");
    }
  }, [amount]);
  useEffect(() => {
    if (fee) {
      setActualTokens(amount - fee);
    }
  }, [fee]);

  return { amount, fee, actualToken, setAmount };
};

const Stake = ({
  open,
  onClose,
  expired = false,
  depositStake,
  feePercent,
}) => {
  const { actualToken, amount, fee, setAmount } = useStakeInfo(feePercent);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiPaper-root": {
          backgroundColor: "#212429",
          borderRadius: "2rem",
        },
      }}
      fullWidth
      maxWidth="sm"
    >
      <Box className="modal-header-stake">
        <DialogTitle
          id="responsive-dialog-title"
          sx={{ fontWeight: 600, color: "#fff", mt: 5 }}
        >
          Stake Your Tokens
          {/* Staking has been disabled */}
        </DialogTitle>
      </Box>

      <DialogContent>
        <Ternary
          when={expired}
          then={
            <Box>
              <Typography
                variant="subtitle2"
                fontWeight={700}
                style={{ fontSize: "0.775rem", color: "#ccc" }}
                mt={-3}
              >
                The ability to stake into this contract has now been disabled.
                You can still claim your rewards (without penalty) and unstake
                your tokens. However, if you unstake before the contract matures
                later this month, you will incur a penalty.
              </Typography>

              <Typography
                variant="subtitle2"
                fontWeight={700}
                style={{ fontSize: "0.775rem", color: "#ccc" }}
                mt={3}
              >
                Please see the official BTAF token channel for more information.
              </Typography>
            </Box>
          }
          otherwise={
            <>
              <Box
                sx={{
                  display: "grid",
                  rowGap: 2,
                  columnGap: 2,
                  marginTop: 1,
                  gridTemplateColumns: {
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(1, 1fr)",
                  },
                  "& .MuiFormControl-root": {
                    border: "solid 1px #cccccc2b",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#ffffff00",
                    },
                  },
                }}
              >
                <TextField
                  label="Number of tokens to stake *"
                  id="custom-css-outlined-input"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  type="number"
                  sx={{
                    borderRadius: "1rem",
                    "& .MuiInputLabel-root": {
                      color: "#fff",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "1rem",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#fff",
                    },
                  }}
                />
                <TextField
                  label={`${feePercent * 100}% Fee:`}
                  id="custom-css-outlined-input"
                  value={fee}
                  sx={{
                    borderRadius: "1rem",
                    "& .MuiInputLabel-root": {
                      color: "#fff",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "1rem",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#fff",
                    },
                  }}
                />
                <TextField
                  label="Actual Number of Tokens to be Staked:"
                  id="custom-css-outlined-input"
                  value={actualToken}
                  sx={{
                    borderRadius: "1rem",
                    "& .MuiInputLabel-root": {
                      color: "#fff",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "1rem",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#fff",
                    },
                  }}
                />
              </Box>
              <Typography
                variant="subtitle2"
                fontWeight={700}
                style={{ fontSize: "0.775rem", color: "#ccc" }}
                mt={5}
              >
                <span style={{ fontSize: "0.775rem" }}>* Reminder :&nbsp;</span>
                <br /> There is a 2.5% staking fee based on the number of tokens
                staked . If you do not wish to proceed,please select the cancel
                button.
              </Typography>
              <DialogActions sx={{ marginBottom: "2rem" }}>
                <Button
                  onClick={onClose}
                  sx={{
                    borderRadius: "1rem",
                    textTransform: "capitalize",
                    mt: 1,
                    fontWeight: 600,
                    textAlign: "left",
                  }}
                  autoFocus
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    depositStake(amount);
                  }}
                  sx={{
                    borderRadius: "1rem",
                    textTransform: "capitalize",
                    mt: 1,
                    fontWeight: 600,
                  }}
                  className="modal-header-stake-btn"
                >
                  Proceed to stake
                </Button>
              </DialogActions>
            </>
          }
        />
      </DialogContent>
    </Dialog>
  );
};

export default Stake;
