import { Box, Button, Grid } from "@mui/material";
import React from "react";
import Tuffy_Pay from "src/assets/Tuffy_pay.png";
import Iconify from "src/components/Iconify";
import Footer from "src/components/footer";
import SocialIcons from "src/components/social-icons";
import "../style.scss";
import BtafPayWork from "./btafPayWork";
import Contact from "./contact";
import Contents from "./contents";
import Delighted from "./delighted";
import Merchants from "./merchants";
import WhatIs from "./whatIs";

const Pay = () => {
  return (
    <>
      <Box className="main">
        <Box className="section banner banner-section">
          <Box className="container">
            <Box className="before-styles-pay">
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <Box className="banner-inner" sx={{ marginTop: "8rem" }}>
                    <h1 className="heading-xl-pay">
                      Use BTAF tokens to purchase goods and services on
                      platforms globally
                    </h1>
                    <Button
                      sx={{
                        textTransform: "none",
                        borderRadius: "1rem",
                        fontWeight: "800",
                        padding: "1rem",
                      }}
                      variant="contained"
                      className="connect-btn"
                      size="large"
                      endIcon={
                        <Iconify icon="material-symbols:arrow-right-alt-rounded" />
                      }
                      href="https://bitcointaf.com/create-account"
                      target="_blank"
                    >
                      Start Now
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box>
                    <img src={Tuffy_Pay} alt="tuffy" />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <SocialIcons />
        </Box>
      </Box>
      <Delighted />
      <WhatIs />
      <BtafPayWork />
      <Contents />
      <Merchants />
      <Contact />
      <Footer />
    </>
  );
};

export default Pay;
