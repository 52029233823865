import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import Tuffy from "src/assets/Tuffy_Buy.png";
import AuditPdf from "src/assets/pdf/BTAF_Perpetual_Staking_Audit_3_Smart_Contract_Security_Audit_Revised.pdf";
import { USE_BASE_URL } from "src/config";

const Terms = () => {
  return (
    <>
      <Box className="main" mt={5}>
        <Box className="section banner banner-section">
          <Box className="container ">
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={1}></Grid>
                <Grid item xs={12} md={10}>
                  <Box mt={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Box>
                          <Typography
                            variant="h4"
                            className="terms-head"
                            sx={{ position: "relative", textAlign: "center" }}
                          >
                            STAKING <br />
                            TERMS & CONDITIONS <br /> AUDIT REPORT
                          </Typography>
                          <Box
                            sx={{
                              display: "grid",
                              rowGap: 3,
                              columnGap: 2,
                              marginTop: 3,
                              gridTemplateColumns: {
                                xs: "repeat(1, 1fr)",
                                sm: "repeat(2, 1fr)",
                              },
                            }}
                          >
                            <Button
                              size="large"
                              variant="contained"
                              className="buy-btn"
                              href={AuditPdf}
                              target="_blank"
                              sx={{
                                textTransform: "capitalize",
                                fontWeight: 700,
                              }}
                            >
                              View Audit Report
                            </Button>
                            <Button
                              size="large"
                              variant="contained"
                              className="buy-btn"
                              sx={{
                                textTransform: "capitalize",
                                fontWeight: 700,
                              }}
                              href={`${USE_BASE_URL}/terms-and-conditions/#staking`}
                              target="_blank"
                            >
                              Staking / Unstaking Terms
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box>
                          <img src={Tuffy} className="tuffy-secure-img" />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} md={1}></Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Terms;
