import { Box, Button, Tooltip, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React from "react";
import Iconify from "src/components/Iconify";
import Ternary from "src/components/ternary";
import { useMetaMask } from "src/store/metamask";

const Buttons = ({
  handleClickOpenCalculateReward,
  onConnected,
  handleClickOpen,
  disableStake,
}) => {
  const [data] = useMetaMask();
  const { signerAddress } = data;
  return (
    <Box
      sx={{
        display: "grid",
        rowGap: 3,
        columnGap: 2,
        marginTop: 3,
        gridTemplateColumns: {
          xs: "repeat(1, 1fr)",
          sm: "repeat(3, 1fr)",
        },
      }}
    >
      <Box>
        <Box sx={{ textAlign: "right" }}>
          <Tooltip
            title={
              <Box sx={{ borderRadius: "10px" }}>
                <Typography
                  variant="subtitle2"
                  fontWeight={700}
                  mt={1}
                  style={{ fontSize: "0.775rem" }}
                >
                  Calculate the total rewards that will be generated on the
                  number of tokens staked if you stake now and keep those tokens
                  in the contract until after the maturation date.
                </Typography>
              </Box>
            }
            enterTouchDelay={0}
          >
            <IconButton
              size="small"
              sx={{
                width: 25,
                height: 25,
                color: "#cccccc8c",
              }}
            >
              <Iconify
                icon="mdi:question-mark-circle"
                sx={{
                  "& .MuiBox-root": {
                    "& .MuiButtonBase-root": {
                      margin: "0px -5px -70px 0px",
                    },
                  },
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>
        <Button
          className="three-btn"
          fullWidth
          onClick={handleClickOpenCalculateReward}
        >
          Calculate Rewards
        </Button>
      </Box>

      <Box>
        <Box sx={{ textAlign: "right" }}>
          <Tooltip
            title={
              <Box sx={{ borderRadius: "10px" }}>
                <Typography
                  variant="subtitle2"
                  fontWeight={700}
                  mt={1}
                  style={{ fontSize: "0.775rem" }}
                >
                  Connect your MetaMask wallet in preparation for staking or to
                  view information about stakes you have already made.
                </Typography>
              </Box>
            }
            enterTouchDelay={0}
          >
            <IconButton
              size="small"
              sx={{
                width: 25,
                height: 25,
                color: "#cccccc8c",
              }}
            >
              <Iconify
                icon="mdi:question-mark-circle"
                sx={{
                  "& .MuiBox-root": {
                    "& .MuiButtonBase-root": {
                      margin: "0px -5px -70px 0px",
                    },
                  },
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>

        <Button
          onClick={() => {
            if (!signerAddress) {
              onConnected();
            }
          }}
          fullWidth
          sx={{
            color: "#fff",
            fontWeight: 800,
            textTransform: "capitalize",
          }}
          className="connect-btn"
        >
          {signerAddress ? "Your Wallet Is Connected" : "Connect MetaMask"}
        </Button>
      </Box>

      <Ternary
        when={!disableStake}
        then={
          <Box>
            <Box sx={{ textAlign: "right" }}>
              <Tooltip
                title={
                  <Box sx={{ borderRadius: "10px" }}>
                    <Typography
                      variant="subtitle2"
                      fontWeight={700}
                      style={{ fontSize: "0.775rem" }}
                    >
                      Stake Your Tokens
                    </Typography>
                  </Box>
                }
                enterTouchDelay={0}
              >
                <IconButton
                  size="small"
                  sx={{
                    width: 25,
                    height: 25,
                    color: "#cccccc8c",
                  }}
                >
                  <Iconify
                    icon="mdi:question-mark-circle"
                    sx={{
                      "& .MuiBox-root": {
                        "& .MuiButtonBase-root": {
                          margin: "0px -5px -70px 0px",
                        },
                      },
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Box>
            <Button
              // disabled
              className="three-btn"
              onClick={handleClickOpen}
              fullWidth
            >
              Stake
            </Button>
          </Box>
        }
      />
    </Box>
  );
};

export default Buttons;
