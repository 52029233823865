import { Box } from "@mui/material";
import React from "react";
import Iconify from "src/components/Iconify";
import Map from "src/components/map";
import _socialLink from "./_social-links";

const SocialLinks = () => {
    return (
        <Box className="footer-icon">
            <Map
                list={_socialLink}
                render={({ href, title, icon }) => (
                    <a
                        href={href}
                        target="_blank"
                        title={title}
                        rel="noreferrer"
                    >
                        <Iconify icon={icon} />
                    </a>
                )}
            />
        </Box>
    );
};

export default SocialLinks;
