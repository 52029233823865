const sections = {
    stakingContract: (contract, timeRemaining) => ({
        className: "all-card",
        showEndDate: false,
        title: "90-DAY /65% APR",
        subTitle: "Accumulated Annual Percentage Rate (APR)",
        stakingContract: contract,
        timeRemaining: timeRemaining,
        disableStake: true,
        feePercent: 0.025,
        dailyReturn: 0.17,
        image: "stake-images/pink.png",
        info: {
            color: "#FA64DC",
            message:
                "This contract has matured.\nYour already staked tokens are no longer generating rewards.\n However, you can now both claim your rewards and safely unstake your tokens without penalty.",
        },
    }),

    stakingContract_35: (contract, timeRemaining) => ({
        className: "all-card all-card-35",
        showEndDate: false,
        title: "90-DAY /35% APR",
        subTitle: "Accumulated Annual Percentage Rate (APR)",
        stakingContract: contract,
        timeRemaining: timeRemaining,
        disableStake: true,
        feePercent: 0.025,
        dailyReturn: 0.095,
        image: "stake-images/purple.png",
        info: {
            color: "#CE87FD",
            message:
                "This contract has matured.\nYour already staked tokens are no longer generating rewards.\n However, you can now both claim your rewards and safely unstake your tokens without penalty.",
        },
    }),

    stakingContract_new: (contract, timeRemaining) => ({
        className: "all-card",
        showEndDate: false,
        title: "90-DAY /35% APR",
        subTitle: "Accumulated Annual Percentage Rate (APR)",
        stakingContract: contract,
        timeRemaining: timeRemaining,
        disableStake: true,
        feePercent: 0.025,
        dailyReturn: 0.095,
        image: "stake-images/green.png",
        info: {
            color: "#00B56E",
            message:
                "This contract has matured.\nYour already staked tokens are no longer generating rewards.\n However, you can now both claim your rewards and safely unstake your tokens without penalty.",
        },
    }),

    stakingContract_selfMaturing: (contract, timeRemaining) => ({
        className: "all-card all-card-35",
        showEndDate: true,
        title: "6 Months / 20% APR",
        subTitle: "Accumulated Annual Percentage Rate (APR)",
        stakingContract: contract,
        timeRemaining: timeRemaining,
        disableStake: false,
        feePercent: 0.025,
        dailyReturn: 0.054,
        image: "stake-images/light-blue.png",
        maturesIn: 180,
        info: {
            color: "#00b2f8",
            message:
                "Staking into this contract is LIVE.\n You can interact with this contract as normal.",
        },
    }),

    private_stake: (contract, timeRemaining) => ({
        className: "all-card all-card-35",
        showEndDate: true,
        title: "6 Months / 25% APR",
        subTitle: "Accumulated Annual Percentage Rate (APR)",
        stakingContract: contract,
        timeRemaining: timeRemaining,
        disableStake: false,
        feePercent: 0.025,
        dailyReturn: 0.068,
        image: "stake-images/light-blue.png",
        maturesIn: 180,
        info: {
            color: "#00b2f8",
            message:
                "Staking into this contract is LIVE.\n You can interact with this contract as normal.",
        },
    }),
};

const generateSections = (data = {}, OP = []) => {
    return OP.map(([contractName, timeRemainingName]) => {
        const [contract, timeRemaining] = [
            data[contractName],
            data[timeRemainingName],
        ];

        return sections[contractName](contract, timeRemaining);
    });
};

export default generateSections;
