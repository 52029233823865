import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useRef } from "react";
import Pan from "src/assets/pan.png";
import QrCode from "src/assets/telegram-qr-code.png";
import Iconify from "src/components/Iconify";

const BuyBTAF = () => {
  const value = useRef("0xcAE3d82D63e2b0094bc959752993D3D3743B5D08");
  const { enqueueSnackbar } = useSnackbar();
  const copy = async () => {
    await navigator.clipboard.writeText(value.current);
    enqueueSnackbar("Copied to clipboard");
  };
  return (
    <>
      <Box className="main">
        <Box className="section banner banner-section">
          <Box className="container ">
            <Typography
              variant="h4"
              className="tier-head"
              sx={{ position: "relative", textAlign: "center" }}
            >
              Buy BTAF to
              <br /> Secure your <br />
              BTAFKey level
              <svg
                width="165"
                height="57"
                viewBox="0 0 165 57"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M108.132 1.32466C122.999 -0.331876 138.154 -0.519677 150.449 2.04187C155.427 3.0789 159.182 4.68046 161.484 6.93236C162.662 8.08509 163.476 9.42683 163.833 10.9379C164.189 12.4462 164.059 13.9893 163.559 15.4993C162.58 18.4534 160.152 21.4092 156.764 24.2611C153.337 27.1456 148.747 30.0749 143.153 32.9302C120.805 44.337 81.5729 54.9921 33.1035 56.499C20.535 56.8897 12.2171 55.9118 7.09463 53.9363C4.51453 52.9413 2.57244 51.628 1.36533 49.9395C0.118979 48.1961 -0.205766 46.2449 0.116937 44.3158C0.72586 40.6758 3.63942 37.0604 6.85068 34.0616C10.1516 30.9792 14.1505 28.1994 17.5515 26.2621C18.5113 25.7153 19.7325 26.0502 20.2792 27.01C20.826 27.9698 20.4911 29.191 19.5313 29.7377C16.349 31.5504 12.6139 34.1526 9.58072 36.9851C6.45787 39.9013 4.43716 42.7338 4.06212 44.9758C3.89284 45.9877 4.06369 46.836 4.61932 47.6132C5.21419 48.4453 6.38047 49.3737 8.53393 50.2042C12.8786 51.8798 20.5286 52.888 32.9792 52.5009C81.0098 51.0077 119.632 40.4447 141.335 29.3674C146.753 26.6017 151.064 23.831 154.188 21.201C157.351 18.5386 159.124 16.1657 159.761 14.2419C160.069 13.3128 160.099 12.5296 159.94 11.8574C159.782 11.1878 159.405 10.4947 158.686 9.79153C157.194 8.33156 154.332 6.93675 149.633 5.95779C137.929 3.51933 123.25 3.66489 108.575 5.30006C93.9248 6.9325 79.4176 10.0333 68.1148 13.4159C67.0566 13.7326 65.942 13.1315 65.6253 12.0733C65.3086 11.0151 65.9097 9.90054 66.9679 9.58386C78.4985 6.13312 93.2412 2.98392 108.132 1.32466Z"
                  fill="#00FFAA"
                ></path>
              </svg>
            </Typography>
            <Box sx={{ mt: 4 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}></Grid>
                <Grid item xs={12} md={6}>
                  <Card className="tire-buy-card">
                    <Box className="title-address">Contract address</Box>
                    <Typography
                      variant="subtitle2"
                      sx={{ color: "#fff", marginTop: 3, marginBottom: 1 }}
                    >
                      BTAF contract address
                    </Typography>
                    <Box className="copy-input">
                      <TextField
                        sx={{
                          margin: "0.8rem 0",
                          color: "#fff",
                          input: {
                            color: "white",
                          },
                        }}
                        fullWidth
                        value={value.current}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title="Copied to clipboard">
                                <IconButton onClick={() => copy()}>
                                  <Iconify
                                    icon="bxs:copy"
                                    sx={{ color: "#fff" }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    <Typography
                      textAlign="center"
                      sx={{ color: "#fff", fontWeight: 700, mt: 2 }}
                    >
                      Join our Official Telegram Chat Channel
                    </Typography>
                    <Box sx={{ justifyContent: "center", display: "flex" }}>
                      <img src={QrCode} width={200} className="qr-code" />
                    </Box>
                    <Box
                      sx={{ display: "flex", justifyContent: "center", mt: 3 }}
                    >
                      <Button
                        className="address-btn"
                        startIcon={<img src={Pan} />}
                        href="https://pancakeswap.finance/swap?outputCurrency=0xcAE3d82D63e2b0094bc959752993D3D3743B5D08"
                        target="_blank"
                      >
                        PancakeSwap
                      </Button>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} md={3}></Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BuyBTAF;
