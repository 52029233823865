import ContractRenderer from "src/components/contract-renderer";
import { NETWORK } from "src/config";
import generateSections from "src/cryptoUtilities/contract-sections";
import getChain from "src/cryptoUtilities/getChain";
import { useMetaMask } from "src/store/metamask";

const OP = [
    ["stakingContract_selfMaturing", "timeRemaining_selfMaturing"],
    ["stakingContract_new", "timeRemaining_new"],
    ["stakingContract_35", "timeRemaining_35"],
    ["stakingContract", "timeRemaining"],
];

const StakeSection = () => {
    const [data] = useMetaMask();

    const sections = generateSections(data, OP);
    const selectedChain = getChain()[NETWORK];

    return (
        <ContractRenderer
            sections={sections}
            apy={selectedChain?.stakingContract_selfMaturing_apy}
        />
    );
};

export default StakeSection;
