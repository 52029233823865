import Map from "src/components/map";
import StakeCard from "./components/card";

const ContractRenderer = ({ sections, apy }) => {
    return (
        <Map
            list={sections}
            render={(item) => {
                const {
                    title,
                    className,
                    subTitle,
                    stakingContract,
                    timeRemaining,
                    feePercent,
                    dailyReturn,
                    disableStake,
                    image,
                    info,
                    showEndDate,
                    maturesIn,
                } = item;
                return (
                    <StakeCard
                        showEndDate={showEndDate}
                        title={title}
                        className={className}
                        subTitle={subTitle}
                        stakingContract={stakingContract}
                        timeRemaining={timeRemaining}
                        feePercent={feePercent}
                        dailyReturn={dailyReturn}
                        disableStake={disableStake}
                        info={info}
                        image={image}
                        maturesIn={maturesIn}
                        apy={apy}
                    />
                );
            }}
        />
    );
};

export default ContractRenderer;
