import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import connect from "src/cryptoUtilities/connect";
import { clearAddress, useAddressDialog } from "src/store/address-dialog";
import { init, useMetaMask } from "src/store/metamask";
import Map from "../map";
import Ternary from "../ternary";

const AddressDialog = () => {
  const [value, dispatch] = useAddressDialog();
  const [_, metamaskDispatch] = useMetaMask();
  const [selected, setSelected] = useState("");
  const [connecting, setConnecting] = useState(false);
  useEffect(() => {
    if (value?.length > 0) {
      setSelected(value?.find(Boolean));
    }
  }, [value]);

  const handleClose = () => {
    dispatch(clearAddress());
  };

  const handleConnect = async () => {
    setConnecting(true);
    const connectedData = await connect(selected);
    metamaskDispatch(init(connectedData));
    handleClose();
  };
  return (
    <Dialog
      open={Boolean(value)}
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      sx={{
        "& .MuiPaper-root": {
          backgroundColor: "#212429",
          borderRadius: "2rem",
        },
      }}
    >
      <DialogTitle sx={{ color: "#fff", mt: 5 }}>
        <Typography variant="h5" sx={{ fontWeight: 800 }}>
          Select Your Preferred Address
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Ternary
          when={Boolean(value)}
          then={
            <FormControl>
              <FormLabel
                sx={{
                  color: "#fff",
                }}
                id="choose-address"
              >
                You seem to have more than one account in your Metamask wallet.
                To which account would you like to connect?
              </FormLabel>
              <RadioGroup
                aria-labelledby="choose-address"
                name="wallet_address"
                value={selected}
                onChange={(_, v) => setSelected(v)}
              >
                <Map
                  list={value}
                  render={(address) => (
                    <FormControlLabel
                      sx={{
                        color: "#fff",
                      }}
                      value={address}
                      control={<Radio sx={{ color: "#fff" }} />}
                      label={address}
                    />
                  )}
                />
              </RadioGroup>
            </FormControl>
          }
        />
        <DialogActions>
          <Button
            sx={{
              borderRadius: "3rem",
              textTransform: "capitalize",
              mt: 1,
              fontWeight: 600,
              color: "#fff",
            }}
            onClick={handleClose}
            variant="outlined"
          >
            cancel
          </Button>
          <LoadingButton
            loading={connecting}
            sx={{
              borderRadius: "3rem",
              textTransform: "capitalize",
              mt: 1,
              fontWeight: 600,
              color: "#fff",
            }}
            variant="contained"
            onClick={handleConnect}
          >
            connect
          </LoadingButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default AddressDialog;
