import ContractRenderer from "src/components/contract-renderer";
import { NETWORK } from "src/config";
import generateSections from "src/cryptoUtilities/contract-sections";
import getChain from "src/cryptoUtilities/getChain";
import { useMetaMask } from "src/store/metamask";

const OP = [["private_stake", "private_stake_timeRemaining"]];

const StakeSection = () => {
    const [data] = useMetaMask();
    const selectedChain = getChain()[NETWORK];

    const sections = generateSections(data, OP);

    return (
        <ContractRenderer
            sections={sections}
            apy={selectedChain.stakingContract_selfMaturing_apy_private}
        />
    );
};

export default StakeSection;
