import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";

const Confirm = ({ open, onClose, withdrawStake }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiPaper-root": {
          backgroundColor: "#212429",
          borderRadius: "2rem",
        },
      }}
      fullWidth
      maxWidth="sm"
    >
      <Box className="modal-header-stake">
        <DialogTitle
          id="responsive-dialog-title"
          sx={{ fontWeight: 600, color: "#fff", mt: 5 }}
        >
          Are you sure you want to unstake now ?
          <Typography
            variant="subtitle2"
            mt={2}
            sx={{ fontSize: "0.775rem", color: "#ccc", fontWeight: 700 }}
          >
            If you are unstaking prior to contract maturation, there will be a
            fee incurred. The burn amount will show you how many tokens will be
            burnt as fees. If you do not wish to proceed, select the ‘cancel’
            button
          </Typography>
        </DialogTitle>
      </Box>
      <DialogContent>
        <Box
          sx={{
            display: "grid",
            rowGap: 1,
            columnGap: 2,
            marginTop: 1,
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              sm: "repeat(1, 1fr)",
            },
            "& .MuiFormControl-root": {
              border: "solid 1px #cccccc2b",
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#ffffff00",
              },
            },
          }}
        ></Box>
        <DialogActions>
          <Button
            onClick={onClose}
            sx={{
              borderRadius: "1rem",
              textTransform: "capitalize",
              mt: 1,
              fontWeight: 600,
            }}
            autoFocus
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              borderRadius: "1rem",
              textTransform: "capitalize",
              mt: 1,
              fontWeight: 600,
            }}
            className="unstake-btn"
            onClick={withdrawStake}
          >
            Confirm
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default Confirm;
