import { Box, Button, Grid } from "@mui/material";
import React, { useRef } from "react";
import IntroSvg from "src/assets/intro.svg";
import LogoPng from "src/assets/logo-png.png";
import Iconify from "src/components/Iconify";
import Footer from "src/components/footer";
import SocialIcons from "src/components/social-icons";
import "../style.scss";
import BuyBTAF from "./buyBTAF";
import Secure from "./secure";
import TierLevels from "./tierLevels";
import WhatIs from "./whatIs";

const Key = () => {
  const viewRef = useRef(null);
  return (
    <>
      <Box className="main">
        <Box className="section banner banner-section">
          <Box className="container">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box className="banner-inner" sx={{ marginTop: "8rem" }}>
                  <h1 className="heading-xl">BTAFKey - Your Unique Tier</h1>
                  <Button
                    sx={{
                      textTransform: "none",
                      borderRadius: "1rem",
                      fontWeight: "800",
                      padding: "1rem",
                    }}
                    variant="contained"
                    className="subscribe-btn"
                    size="large"
                    href="https://taftoken.eo.page/taftoken"
                    target="_blank"
                    onClick={() => {
                      viewRef.current.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }}
                  >
                    Subscribe to our newsletter
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ position: "relative" }}>
                  <Box
                    sx={{
                      position: "absolute",
                      left: "30%",
                      right: "20%",
                      zIndex: 1,
                      top: "30%",
                    }}
                  >
                    <img className="banner-image" src={LogoPng} />
                  </Box>
                  <img className="banner-image animation-svg" src={IntroSvg} />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <SocialIcons />
        </Box>
      </Box>
      <WhatIs />
      <TierLevels />
      <BuyBTAF />
      <Secure />
      <Footer />
    </>
  );
};

export default Key;
