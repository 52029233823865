import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React from "react";
import MetaMaskLogo from "src/assets/metamask.png";
import Iconify from "src/components/Iconify";

const ConnectWallet = ({ open, onClose, onConnected }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiPaper-root": {
          backgroundColor: "#212429",
          borderRadius: "2rem",
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title" sx={{ color: "#fff", mt: 5 }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Iconify icon="ic:outline-close" />
        </IconButton>
        <Typography variant="h5" sx={{ fontWeight: 800 }}>
          Connect Wallet
        </Typography>
      </DialogTitle>

      {/* Connect Wallet  */}
      <DialogContent>
        <Button
          variant="outlined"
          onClick={onConnected}
          sx={{
            borderRadius: "3rem",
            textTransform: "capitalize",
            mt: 1,
            fontWeight: 600,
            color: "#fff",
          }}
          fullWidth
          size="large"
          startIcon={
            <img src={MetaMaskLogo} style={{ margin: "0px 20px 0px 0px" }} />
          }
        >
          Meta Mask
        </Button>
        <Typography
          variant="subtitle2"
          fontWeight={500}
          style={{ color: "#ccc" }}
          mt={2}
          mb={2}
        >
          You don't have any crypto wallet?
        </Typography>
        <Button
          variant="contained"
          sx={{
            borderRadius: "1rem",
            textTransform: "capitalize",
            mt: 1,
            fontWeight: 600,
          }}
          className="connect-btn"
          fullWidth
          size="large"
          href="https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain"
          target="_blank"
        >
          Learn how to connect
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default ConnectWallet;
