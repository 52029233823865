import { Navigate } from "react-router-dom";
import { getSession } from "src/utils/session";

// Only authenticated user can access the components wrapped in this
const AuthGuard = ({ children }) => {
  const session = getSession();
  if (!session) return <Navigate to="/login" />;

  return <>{children}</>;
};

// Only unauthenticated user can access the components wrapped in this
const GuestGuard = ({ children }) => {
  const session = getSession();
  if (session) return <Navigate to="/private-stake" />;

  return <>{children}</>;
};

export { AuthGuard, GuestGuard };
