import { SnackbarProvider } from "notistack";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import AddressDialog from "./components/address-dialog";
import { AuthGuard, GuestGuard } from "./components/helper";
import Layout from "./components/layout";
import Key from "./pages/key";
import Login from "./pages/login";
import NoPage from "./pages/noPage";
import Pay from "./pages/pay";
import PrivateStake from "./pages/private-stake";
import Stake from "./pages/stake";
import AddressDialogContext from "./store/address-dialog";
import MetaMask from "./store/metamask";

function App() {
  return (
    <>
      <AddressDialogContext>
        <MetaMask>
          <SnackbarProvider />
          <BrowserRouter>
            <Layout>
              <Routes>
                <Route path="/">
                  <Route path="stake" element={<Stake />} />
                  <Route
                    path="login"
                    element={
                      <GuestGuard>
                        <Login />
                      </GuestGuard>
                    }
                  />
                  <Route
                    path="private-stake"
                    element={
                      <AuthGuard>
                        <PrivateStake />
                      </AuthGuard>
                    }
                  />
                  <Route path="key" element={<Key />} />
                  <Route path="pay" element={<Pay />} />
                  <Route path="*" element={<NoPage />} />
                </Route>
              </Routes>
            </Layout>
          </BrowserRouter>
          <AddressDialog />
        </MetaMask>
      </AddressDialogContext>
    </>
  );
}

export default App;
