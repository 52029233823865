import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { useMetaMask } from "src/store/metamask";

function tokenAmountToWeiAmount(tokenAmount) {
    return ethers.utils.parseUnits(tokenAmount, 18);
}

function weiAmountToTokenAmount(wei) {
    return ethers.utils.formatUnits(wei, 18);
}

const useStake = (
    stakingContract,
    apy,
    {
        handleClose,
        setOpenLoader,
        setOpenClaim,
        openClaim,
        openUnstake,
        setOpenAreyouSure,
        rewardDialogData,
    }
) => {
    const [data] = useMetaMask();
    const { signerAddress, tokenContract } = data;

    async function checkAllowance(amount) {
        if (!tokenContract) {
            throw new Error("Token contract not connected");
        }
        try {
        } catch (err) {
            console.error(err);
        }

        const weiAmount = tokenAmountToWeiAmount(amount);

        let userAllowance;
        try {
            userAllowance = await tokenContract.allowance(signerAddress, apy);
        } catch (err) {
            console.error(err);
        }

        // If the allowance is less than the amount we want to pay, we need to approve the invoice contract to spend our tokens
        if (userAllowance.lt(weiAmount)) {
            await // We use MaxUint256 because this is the highest value you can use in an approve transaction
            // This reduces the gas cost by a user as often this is the highest value they will ever need
            (await tokenContract.approve(apy, weiAmount)).wait();

            // console.log("wait");
        }
        // console.log("exit");
    }
    async function depositStake(amount) {
        handleClose();
        setOpenLoader(true);

        try {
            // await setup();
            // const amount = depositStakeAmount_ref.current.value;
            await checkAllowance(amount);

            // Convert the amount from BTAF token to wei
            // 1 BTAF token = 10^18 wei
            const weiAmount = tokenAmountToWeiAmount(amount);
            // Call the depositStake function on the staking contract
            const tx = await stakingContract.depositStake(weiAmount);

            await tx.wait();
            setOpenLoader(false);
            getNumberOfStake();
        } catch (err) {
            console.error(err);
            setOpenLoader(false);
        }
    }

    const [numberOfStake, setNumberOfStake] = useState(0);
    const [stakingInfo, setStackingInfo] = useState([]);

    const getNumberOfStake = async () => {
        setStackingInfo([]);
        const count = await stakingContract.getInvestorStakeCount(
            signerAddress
        );
        setNumberOfStake(count.toNumber());
        return true;
    };
    useEffect(() => {
        if (signerAddress) {
            getNumberOfStake();
        }
    }, [signerAddress]);

    const test = async (index) => {
        const stakeInfo = await stakingContract.getInvestorStake(
            signerAddress,
            index
        );
        return {
            unStaked: Boolean(stakeInfo.dateWithdrawn.toNumber()),
            staker: stakeInfo.staker,
            id: index,
            amount: ethers.utils.formatUnits(stakeInfo.amount, 18),
            startDate: new Date(
                stakeInfo.startDate.toNumber() * 1000
            ).toString(),
            reward: await getReward(index),
            stakeBurnt: weiAmountToTokenAmount(stakeInfo.stakeBurnt),
        };
    };

    const wrapper = async () => {
        const res = [...new Array(numberOfStake)].map(async (_, i) => {
            return test(i);
        });
        // console.log(await Promise.all(res));
        setStackingInfo(await Promise.all(res));
    };

    useEffect(() => {
        if (Boolean(numberOfStake)) {
            wrapper();
        }
    }, [numberOfStake]);

    async function claimReward() {
        // const idx = document.getElementById("claimRewardStakeId").value;
        const idx = rewardDialogData.id;
        setOpenClaim(false);
        setOpenLoader(true);
        // await setup();

        const startingBalance = await tokenContract.balanceOf(signerAddress);

        // Call the claimReward function on the staking contract
        try {
            const tx = await stakingContract.claimReward(idx);
            await tx.wait();
            const newBalance = await tokenContract.balanceOf(signerAddress);
            const claim = weiAmountToTokenAmount(
                newBalance.sub(startingBalance)
            );

            setOpenLoader(false);
        } catch (err) {
            console.error(err);
            setOpenLoader(false);
        }

        // rewardClaimed_ref.current.innerText = `${claim} reward claimed`;
        // document.getElementById(
        //   "rewardClaimed"
        // ).innerText = `${claim} reward claimed`;
    }

    async function getReward(idx) {
        // await setup();
        const data = await stakingContract.previewInvestorClaimReward(
            signerAddress,
            idx
        );

        setReward(weiAmountToTokenAmount(data));
        return weiAmountToTokenAmount(data);
    }

    const [reward, setReward] = useState(0);
    useEffect(() => {
        if (openClaim) {
            getReward(rewardDialogData.id);
        }
    }, [openClaim]);
    const [withdrawAmount, setWithdrawAmount] = useState("");
    const [burnAmount, setBurnOut] = useState("");
    useEffect(() => {
        async function test(idx) {
            // const idx = document.getElementById(
            //   "previewInvestorWithdrawRewardId"
            // ).value;
            // const addressx = document.getElementById(
            //   "previewInvestorWithdrawRewardAddress"
            // ).value;
            // const addressx = previewInvestorWithdrawRewardAddress_ref.current.value;
            // await setup();

            // Call the listStakes function on the staking contract
            try {
                const withdrawPreview =
                    await stakingContract.previewInvestorWithdrawStake(
                        signerAddress,
                        idx
                    );
                const withdrawAmount = withdrawPreview[0];
                const burnAmount = withdrawPreview[1];
                setWithdrawAmount(weiAmountToTokenAmount(withdrawAmount));
                setBurnOut(weiAmountToTokenAmount(burnAmount));
            } catch (err) {
                console.log(err.message);
            }

            // console.log("Stake ID", idx);
            // console.log("Staker", signerAddress);
            // document.getElementById(
            //   "withdrawPreview"
            // ).innerText = `Withdraw preview amount: ${weiAmountToTokenAmount(
            //   withdrawAmount
            // )}`;
            // withdrawPreview_ref.current.innerText = `Withdraw preview amount: ${weiAmountToTokenAmount(
            //   withdrawAmount
            // )}`;
            // burnPreview_ref.current.innerText = `Burn preview: ${weiAmountToTokenAmount(
            //   burnAmount
            // )}`;
            // document.getElementById(
            //   "burnPreview"
            // ).innerText = `Burn preview: ${weiAmountToTokenAmount(burnAmount)}`;
        }
        if (openUnstake) {
            test(rewardDialogData.id);
        }
    }, [openUnstake]);

    async function withdrawStake() {
        setOpenAreyouSure(false);
        handleClose();
        setOpenLoader(true);
        const idx = rewardDialogData.id;

        // await setup();

        const stake = await stakingContract.getStake(idx);
        const startingBalance = await tokenContract.balanceOf(signerAddress);

        // Call the withdrawStake function on the staking contract
        const tx = await stakingContract.withdrawStake(idx);
        await tx.wait();
        setNumberOfStake(0);
        getNumberOfStake();
        setOpenLoader(false);
        // const newBalance = await tokenContract.balanceOf(signerAddress);
        // const withdrawn = weiAmountToTokenAmount(newBalance.sub(startingBalance));
        // // Note the use of BigNumber here rather than then one provided by ethers
        // // This is because the BigNumber provided by ethers only takes integers
        // const burnt = new BigNumber(stake.amount.toString()).minus(withdrawn);
        // console.log(
        //   `${withdrawn} withdrawn (${weiAmountToTokenAmount(
        //     burnt.toString()
        //   )} burnt)`
        // );
        // withdrawnOutput_ref.current.innerText = `${withdrawn} withdrawn (${burnt.toString()} burnt`;
        // document.getElementById(
        //   "withdrawnOutput"
        // ).innerText = `${withdrawn} withdrawn (${burnt.toString()} burnt`;
    }

    return {
        depositStake,
        stakingInfo,
        withdrawStake,
        withdrawAmount,
        burnAmount,
        reward,
        claimReward,
        getNumberOfStake,
        setNumberOfStake,
    };
};

export default useStake;
