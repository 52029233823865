import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { PRIVATE_LOGIN_ORIGIN } from "src/config";
import { setSession } from "src/utils/session";
import { LoginButton, StyledTextField } from "../..";

const TwoFactorAuthDialog = ({ open, onClose, methods, setNotPurchased }) => {
    const {
        handleSubmit,
        register,
        setError,
        formState: { errors, isSubmitting },
    } = methods;
    const navigate = useNavigate();
    const onSubmit = async ({ product_id, ...inputData }) => {
        const reqData = new FormData();
        Object.entries(inputData).forEach(([k, v]) => reqData.append(k, v));
        product_id.forEach((id) => reqData.append("product_id[]", id));
        try {
            const res = await fetch(`${PRIVATE_LOGIN_ORIGIN}/api/twofaverify`, {
                method: "POST",
                body: reqData,
            });
            const { access_token, product_active, message } = await res.json();

            if (message) {
                setError("code", { message });
                return;
            }
            const isProductPurchased = product_active === 1;

            if (isProductPurchased) {
                setSession(access_token);
                navigate("/private-stake");
            } else {
                onClose();
                setNotPurchased(true);
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
            sx={{
                "& .MuiPaper-root": {
                    backgroundColor: "#212429",
                    borderRadius: "1rem",
                },
                "& .MuiFormControl-root": {
                    border: "solid 1px #cccccc2b",
                },
                "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                        borderColor: "#ffffff00",
                    },
                },
            }}
        >
            <DialogTitle sx={{ fontWeight: 600, color: "#fff" }}>
                Two-Factor Authentication (2FA)
            </DialogTitle>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <StyledTextField
                            {...register("code")}
                            fullWidth
                            label="Enter OTP"
                            error={Boolean(errors.code)}
                            helperText={errors.code?.message}
                        />
                        <DialogActions>
                            <LoginButton
                                loading={isSubmitting}
                                type="submit"
                                fullWidth
                            >
                                verify
                            </LoginButton>
                        </DialogActions>
                    </DialogContent>
                </form>
            </FormProvider>
        </Dialog>
    );
};

export default TwoFactorAuthDialog;
