export const setSession = (token) => {
  localStorage.setItem("access-token", token);
};

export const getSession = () => {
  return localStorage.getItem("access-token");
};

export const getBearerToken = () => {
  return `Bearer ${getSession()}`;
};
