import { Box, Card, Grid, Typography } from "@mui/material";
import LogoSvg from "src/assets/logo.png";
import { USE_BASE_URL } from "src/config";
import Resources from "./components/resources";
import SocialLinks from "./components/social-links";

const Footer = () => {
    return (
        <div>
            <Box className="main" sx={{ mt: "3rem" }}>
                <Box className="section banner banner-section">
                    <Box className="container ">
                        <Card className="footer-card-box">
                            <Box className="footer-card">
                                <Box sx={{ flexGrow: 1 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={5}>
                                            <Box
                                                sx={{
                                                    textAlign: "left",
                                                    color: "rgba(224,224,255,.6)",
                                                }}
                                            >
                                                <p>
                                                    <a
                                                        href={USE_BASE_URL}
                                                        target="_blank"
                                                        className="brand"
                                                    >
                                                        <img
                                                            src={LogoSvg}
                                                            width={30}
                                                        />
                                                        BTAF token
                                                    </a>
                                                </p>
                                                <Typography
                                                    variant="subtitle2"
                                                    mt={2}
                                                >
                                                    185 Kelvin Grove Rd, Kelvin
                                                    Grove,
                                                    <br /> Brisbane, QLD 4059
                                                    Australia
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    mt={3}
                                                >
                                                    2024 © BitcoinTAF (BTAF).
                                                    All rights reserved.
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} md={2}>
                                            <Box sx={{ textAlign: "left" }}>
                                                <Typography
                                                    sx={{
                                                        color: "#fff",
                                                        fontSize: "13px",
                                                        fontWeight: "600",
                                                        letterSpacing: "1px",
                                                        mb: 2,
                                                    }}
                                                >
                                                    DOCUMENTS
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    fontWeight={700}
                                                    className="footer-menus"
                                                >
                                                    <a
                                                        href={`${USE_BASE_URL}privacy-policy/`}
                                                        target="_blank"
                                                    >
                                                        Privacy
                                                    </a>
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    fontWeight={700}
                                                    className="footer-menus"
                                                >
                                                    <a
                                                        href={`${USE_BASE_URL}terms-and-conditions/`}
                                                        target="_blank"
                                                    >
                                                        Terms and Conditions
                                                    </a>
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    fontWeight={700}
                                                    className="footer-menus"
                                                >
                                                    <a
                                                        href={`${USE_BASE_URL}site-access-rules/`}
                                                        target="_blank"
                                                    >
                                                        Site Access Rules
                                                    </a>
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} md={2}>
                                            <Resources />
                                            {/*<Box sx={{ textAlign: "left" }}>
                                                <Typography
                                                    sx={{
                                                        color: "#fff",
                                                        fontSize: "13px",
                                                        fontWeight: "600",
                                                        letterSpacing: "1px",
                                                        mb: 2,
                                                    }}
                                                >
                                                    RESOURCES
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    fontWeight={700}
                                                    className="footer-menus"
                                                >
                                                    <a
                                                        href={`${USE_BASE_URL}/about-us/`}
                                                        target="_blank"
                                                    >
                                                        About us
                                                    </a>
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    fontWeight={700}
                                                    className="footer-menus"
                                                >
                                                    <a
                                                        href="https://btaftoken.io/files/whitePaper.pdf"
                                                        target="_blank"
                                                    >
                                                        Whitepaper
                                                    </a>
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    fontWeight={700}
                                                    className="footer-menus"
                                                >
                                                    <a
                                                        href="https://medium.com/@TAFtoken"
                                                        target="_blank"
                                                    >
                                                        Blog
                                                    </a>
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    fontWeight={700}
                                                    className="footer-menus"
                                                >
                                                    <a
                                                        href="https://help.btaftoken.io"
                                                        target="_blank"
                                                    >
                                                        Support
                                                    </a>
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    fontWeight={700}
                                                    className="footer-menus"
                                                >
                                                    <a
                                                        href={`${USE_BASE_URL}`}
                                                        target="_blank"
                                                    >
                                                        Media Kit
                                                    </a>
                                                </Typography>
                                            </Box>*/}
                                        </Grid>
                                        <Grid item xs={6} md={3}>
                                            <Box sx={{ textAlign: "left" }}>
                                                <Typography
                                                    sx={{
                                                        color: "#fff",
                                                        fontSize: "13px",
                                                        fontWeight: "600",
                                                        letterSpacing: "1px",
                                                        mb: 2,
                                                    }}
                                                >
                                                    FOLLOW
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    fontWeight={700}
                                                    className="footer-menus"
                                                >
                                                    email
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    fontWeight={700}
                                                    className="footer-menus"
                                                >
                                                    <a
                                                        href="mailto:btaftoken@bitcointaf.com"
                                                        target="_blank"
                                                        style={{
                                                            color: "#fff",
                                                        }}
                                                    >
                                                        btaftoken@bitcointaf.com
                                                    </a>
                                                </Typography>
                                                <SocialLinks />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                </Box>
            </Box>
        </div>
    );
};

export default Footer;
