import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const Contents = () => {
  return (
    <div>
      <Box className="main" sx={{ mt: "5rem" }}>
        <Box className="section banner banner-section what-is-pay_top-section_content">
          <Box
            className="container"
            sx={{ p: 2, justifyContent: "center", display: "flex" }}
          >
            <Box className="content-parent">
              <Typography className="content-para">
                BTAFPay is designed to allow the merchant the ability to choose
                their proprietary token, or other cryptocurrencies, as accepted
                forms of payment for goods or services.
              </Typography>
              <Typography className="content-para">
                No conventional currencies, no debit cards or bank accounts are
                needed. And because BTAFPay rides on the BSC network, fees are
                minimal and transaction times are almost instantaneous.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Contents;
