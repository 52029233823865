import { Box, Button, Card, Typography } from "@mui/material";
import React from "react";
import BTAFTokenCoinsStack from "src/assets/BTAFToken-Coins-Stack.png";
import Iconify from "src/components/Iconify";
import "./style.css";

const Merchants = () => {
  return (
    <Box className="main">
      <Box className="section banner banner-section">
        <Box className="container">
          <Card className="whatis-card-box">
            <Box className="whatis-card">
              <Box>
                <Typography variant="h4" className="what-head">
                  How can Merchants benefit from using BTAFPay?
                </Typography>
                <Typography variant="subtitle1" sx={{ color: "#fff", mt: 2 }}>
                  As a merchant that needs to collect payments for products or
                  services, BTAFPay offers a unique integrated Defi payment
                  solution that can be used as a stand-alone payment method. It
                  can also be provided as an additional option alongside
                  traditional centralized payment platforms.
                </Typography>
                <Button
                  sx={{
                    textTransform: "none",
                    borderRadius: "1rem",
                    fontWeight: "800",
                    marginTop: "1rem",
                  }}
                  variant="contained"
                  className="connect-btn"
                  size="large"
                  target="_blank"
                  href="mailto:btaftoken@bitcointaf.com"
                  endIcon={
                    <Iconify icon="material-symbols:arrow-right-alt-rounded" />
                  }
                >
                  Contact Us
                </Button>
              </Box>
              <img
                src={BTAFTokenCoinsStack}
                className="stake-img mobile-dsplay-none"
              />
            </Box>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default Merchants;
