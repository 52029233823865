import { Box, Button, Grid, Stack } from "@mui/material";
import IntroSvg from "src/assets/intro.svg";
import LogoPng from "src/assets/logo-png.png";
import Iconify from "src/components/Iconify";
import SocialIcons from "src/components/social-icons";
import ipoImg from "./assets/IPO.png";

const Main = ({ viewRef }) => {
    return (
        <Box className="main">
            <Box className="section banner banner-section">
                <Box className="container">
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={6}>
                            <Box
                                className="banner-inner"
                                sx={{ marginTop: "5rem" }}
                            >
                                <Box>
                                    <h1
                                        style={{
                                            display: "inline",
                                        }}
                                    >
                                        BitcoinTAF & BTAF token welcomes all
                                    </h1>{" "}
                                    <img
                                        style={{ display: "inline" }}
                                        src={ipoImg}
                                        width={55}
                                    />{" "}
                                    <h1 style={{ display: "inline" }}>
                                        members to your very own staking page!
                                    </h1>
                                </Box>
                                <Stack direction="row" spacing={2}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            textTransform: "none",
                                            borderRadius: "1rem",
                                            fontWeight: "800",
                                            padding: "1rem",
                                        }}
                                        className="stake-token-btn"
                                        size="large"
                                        onClick={() => {
                                            viewRef.current.scrollIntoView({
                                                behavior: "smooth",
                                                block: "start",
                                            });
                                        }}
                                    >
                                        Stake your Tokens
                                    </Button>
                                    {/*<img src={ipoImg} width={100} />*/}
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ position: "relative" }}>
                                <Box
                                    sx={{
                                        position: "absolute",
                                        left: "30%",
                                        right: "20%",
                                        zIndex: 1,
                                        top: "30%",
                                    }}
                                >
                                    <img
                                        className="banner-image"
                                        src={LogoPng}
                                    />
                                </Box>
                                <img
                                    className="banner-image animation-svg"
                                    src={IntroSvg}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <SocialIcons />
            </Box>
        </Box>
    );
};

export default Main;
