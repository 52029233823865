import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";

const UnStake = ({
  open,
  onClose,
  setOpenAreyouSure,
  withdrawAmount,
  burnAmount,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiPaper-root": {
          backgroundColor: "#212429",
          borderRadius: "2rem",
        },
      }}
      fullWidth
      maxWidth="sm"
    >
      <Box className="modal-header-unstake">
        <DialogTitle
          id="alert-dialog-title"
          sx={{ fontWeight: 600, color: "#fff", mt: 5 }}
        >
          Unstake Amount
        </DialogTitle>
      </Box>
      <DialogContent>
        <Typography variant="subtitle2" color="#fff" fontWeight={500}>
          Available for Withdrawal&nbsp;:
          <span style={{ fontWeight: 600, fontSize: "15px", color: "#ccc" }}>
            &nbsp; {withdrawAmount}
          </span>
        </Typography>
        <Typography variant="subtitle2" color="#fff" fontWeight={500} mt={1}>
          Burn Amount&nbsp;:
          <span style={{ fontWeight: 600, fontSize: "15px", color: "#ccc" }}>
            &nbsp; {burnAmount}
          </span>
        </Typography>
        <Typography
          variant="subtitle2"
          mt={2}
          sx={{ fontSize: "0.775rem", color: "#ccc", fontWeight: 700 }}
        >
          You can only unstake the full amount available for withdrawal . If you
          are unstaking prior to contract maturation, there will be a fee
          incurred. The burn amount will show you how many tokens will be burnt
          as fees. If you do not wish to proceed, select the ‘Cancel’ button
        </Typography>
        <DialogActions sx={{ marginBottom: "2rem" }}>
          <Button
            onClick={onClose}
            sx={{
              borderRadius: "1rem",
              textTransform: "capitalize",
              mt: 1,
              fontWeight: 600,
            }}
            autoFocus
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              onClose();
              setOpenAreyouSure(true);
            }}
            variant="contained"
            sx={{
              borderRadius: "1rem",
              textTransform: "capitalize",
              mt: 1,
              fontWeight: 600,
            }}
            className="unstake-btn"
          >
            Unstake Tokens
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default UnStake;
