import getEthereumProvider from "./get-ethereum-provider";

const getAddresses = async () => {
  const metamaskProvider = await getEthereumProvider();
  return (
    (await metamaskProvider.request({
      method: "eth_requestAccounts",
    })) || []
  );
};

export default getAddresses;
