const _socialLink = [
    {
        href: "https://x.com/btaftoken",
        title: "twitter",
        icon: "fa6-brands:square-x-twitter",
    },
    {
        href: "https://t.me/taftoken",
        title: "telegram",
        icon: "ic:baseline-telegram",
    },
    {
        href: "https://medium.com/@TAFtoken",
        title: "medium",
        icon: "simple-icons:medium",
    },
    {
        href: "https://www.youtube.com/channel/UCQ_UZw1Av5cSsQ6BB9Wq63g",
        title: "youtube",
        icon: "ri:youtube-fill",
    },
    {
        href: "https://www.facebook.com/btaftoken.io",
        title: "facebook",
        icon: "ic:baseline-facebook",
    },
    {
        href: "https://www.instagram.com/Taftoken",
        title: "instagram",
        icon: "bxl:instagram",
    },
];

export default _socialLink;
