import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Backdrop,
    Box,
    CircularProgress,
    Stack,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Iconify from "src/components/Iconify";
import Ternary from "src/components/ternary";
import useSetup from "src/cryptoUtilities/useSetup";
import { useMetaMask } from "src/store/metamask";
import useStake from "../hooks/useStake";
import Buttons from "./buttons";
import CalculateReward from "./calculateReward";
import Claim from "./dialogs/claim";
import Confirm from "./dialogs/confirm";
import ConnectWallet from "./dialogs/connectWallet";
import UnStake from "./dialogs/unStake";
import MyStakingInfo from "./myStakingInfo";
import Stake from "./stake";
import StakeTable from "./table";
import TimeRemaining from "./timeRemaining";
import Title from "./title";

const StakeCard = ({
    stakingContract,
    timeRemaining,
    disableStake = false,
    title,
    subTitle,
    feePercent,
    dailyReturn,
    className,
    info,
    image,
    showEndDate,
    maturesIn,
    apy,
}) => {
    const [data] = useMetaMask();
    const setup = useSetup();
    const { signerAddress } = data;
    const [openConnect, setOpenConnect] = useState(false);
    const [open, setOpen] = useState(false);
    const [openCalculateReward, setOpenCalculateReward] = useState(false);
    const [openAreyouSure, setOpenAreyouSure] = useState(false);

    const [openLoader, setOpenLoader] = useState(false);

    const handleClickOpenCalculateReward = () => {
        if (connect) {
            setOpenCalculateReward(true);
        } else {
            setOpenConnect(true);
        }
    };

    const handleClickOpen = () => {
        if (connect) {
            setOpen(true);
        } else {
            setOpenConnect(true);
        }
    };
    const handleClose = () => {
        setOpen(false);
        setOpenClaim(false);
        setOpenUnstake(false);
        setOpenCalculateReward(false);
        setOpenAreyouSure(false);
        setOpenLoader(false);
        setOpenConnect(false);
    };
    const [connect, isConnected] = useState(false);

    useEffect(() => {
        if (connect) setOpenConnect(false);
    }, [connect]);

    const onConnected = async () => {
        const data = await setup();
        if (data) {
            isConnected(true);
            setOpenConnect(false);
        } else {
            setOpenConnect(true);
        }
    };

    useEffect(() => {
        if (signerAddress) isConnected(signerAddress);
    }, [signerAddress]);

    const [openClaim, setOpenClaim] = useState(false);

    const handleClickOpenClaim = () => {
        setOpenClaim(true);
    };

    const [openUnstake, setOpenUnstake] = useState(false);

    const handleClickOpenUnstake = () => {
        setOpenUnstake(true);
    };

    const [rewardDialogData, setRewardDialogData] = useState([]);

    const [dayData, setDayData] = useState(0);

    const {
        depositStake,
        stakingInfo,
        burnAmount,
        withdrawAmount,
        withdrawStake,
        reward,
        getNumberOfStake,
        claimReward,
        setNumberOfStake,
    } = useStake(stakingContract, apy, {
        openClaim,
        openUnstake,
        handleClose,
        setOpenAreyouSure,
        setOpenClaim,
        rewardDialogData,
        setOpenLoader,
    });

    const extractTimeRemaining =
        (stakingContract) =>
        async (id, cb = () => {}) => {
            try {
                const timeRemaining = await stakingContract.getTimeRemaining(
                    id
                );
                cb(timeRemaining.toNumber());
            } catch (err) {
                return 0;
            }
        };

    return (
        <>
            <Accordion
                className={className}
                sx={{ backgroundColor: "#d6d6d600", marginTop: 3 }}
            >
                <AccordionSummary
                    expandIcon={
                        <Iconify
                            icon="mdi:chevron-down"
                            sx={{ color: "#fff " }}
                        />
                    }
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{
                            width: "95%",
                        }}
                    >
                        <Ternary
                            when={Boolean(info.message)}
                            then={
                                <Typography
                                    textAlign="left"
                                    sx={{
                                        color: info.color,
                                        mt: 2,
                                        whiteSpace: "pre-line",
                                    }}
                                    variant="body2"
                                >
                                    {info.message}
                                </Typography>
                            }
                        />
                        <Title
                            title={title}
                            subTitle={subTitle}
                            image={image}
                        />
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <Box mt={3}>
                        <Box mt={4}>
                            <TimeRemaining
                                setDayData={setDayData}
                                timeRemaining={timeRemaining}
                            />

                            <Buttons
                                handleClickOpen={handleClickOpen}
                                handleClickOpenCalculateReward={
                                    handleClickOpenCalculateReward
                                }
                                onConnected={onConnected}
                                disableStake={disableStake}
                            />

                            <MyStakingInfo />
                        </Box>
                        <Ternary
                            when={connect}
                            then={
                                <StakeTable
                                    showEndDate={showEndDate}
                                    extractTimeRemaining={extractTimeRemaining(
                                        stakingContract
                                    )}
                                    getNumberOfStake={getNumberOfStake}
                                    handleClickOpenClaim={handleClickOpenClaim}
                                    handleClickOpenUnstake={
                                        handleClickOpenUnstake
                                    }
                                    setNumberOfStake={setNumberOfStake}
                                    setRewardDialogData={setRewardDialogData}
                                    stakingInfo={stakingInfo}
                                />
                            }
                            otherwise={
                                <Box className="empty-box">
                                    <Typography fontWeight={500}>
                                        YOU HAVE NOT STAKED IN THIS CONTRACT YET
                                    </Typography>
                                </Box>
                            }
                        />
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Stake
                feePercent={feePercent}
                expired={disableStake}
                open={open}
                onClose={handleClose}
                depositStake={depositStake}
            />

            {/* reward dialog  */}

            <CalculateReward
                dailyReturn={dailyReturn}
                feePercent={feePercent}
                onClose={handleClose}
                open={openCalculateReward}
                dayData={maturesIn ? maturesIn : dayData}
            />

            <Claim
                reward={reward}
                claimReward={claimReward}
                onClose={handleClose}
                open={openClaim}
            />

            <UnStake
                burnAmount={burnAmount}
                withdrawAmount={withdrawAmount}
                onClose={handleClose}
                open={openUnstake}
                setOpenAreyouSure={setOpenAreyouSure}
            />
            {/* are your sure  */}
            <Confirm
                onClose={handleClose}
                open={openAreyouSure}
                withdrawStake={withdrawStake}
            />

            {/* connect check wallet  */}

            <ConnectWallet
                onClose={handleClose}
                onConnected={onConnected}
                open={openConnect}
            />

            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 9,
                }}
                open={openLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};

export default StakeCard;
