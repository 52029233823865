import { Box } from "@mui/material";
import { useRef } from "react";
import Footer from "src/components/footer";

import "../style.css";
import "../style.scss";
import BtafStake from "./component/btaf-stake";
import Main from "./component/sections/main";
import Terms from "./component/terms";

const PrivateStake = () => {
    const viewRef = useRef(null);

    return (
        <>
            <Main viewRef={viewRef} />
            <Box ref={viewRef}>
                <BtafStake />
            </Box>
            <Terms />
            <Footer />
        </>
    );
};

export default PrivateStake;
