const privateStakeAbi = [
    {
        inputs: [
            { internalType: "address", name: "_tokenAddress", type: "address" },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "previousOwner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "OwnershipTransferred",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "bool",
                name: "value",
                type: "bool",
            },
        ],
        name: "PausedSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "staker",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "stakeIndex",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "RewardClaimed",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "staker",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "stakeIndex",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "feesCharged",
                type: "uint256",
            },
        ],
        name: "StakeDeposited",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "staker",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "stakeIndex",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "stakeRefunded",
                type: "uint256",
            },
        ],
        name: "StakeRefunded",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "staker",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "stakeIndex",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "stakeWithdrawn",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "stakeBurnt",
                type: "uint256",
            },
        ],
        name: "StakeWithdrawn",
        type: "event",
    },
    {
        inputs: [{ internalType: "uint256", name: "index", type: "uint256" }],
        name: "claimReward",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
        name: "depositStake",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "index", type: "uint256" }],
        name: "getInvestor",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getInvestorCount",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "investor", type: "address" },
            { internalType: "uint256", name: "index", type: "uint256" },
        ],
        name: "getInvestorStake",
        outputs: [
            {
                components: [
                    {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "reward",
                        type: "uint256",
                    },
                    {
                        internalType: "address",
                        name: "staker",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "startDate",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "maturationDate",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "dateWithdrawn",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "totalRewardClaimed",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "stakeBurnt",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "stakingFee",
                        type: "uint256",
                    },
                ],
                internalType: "struct PerpetualStaking.Stake",
                name: "",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "investor", type: "address" },
        ],
        name: "getInvestorStakeCount",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "index", type: "uint256" }],
        name: "getStake",
        outputs: [
            {
                components: [
                    {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "reward",
                        type: "uint256",
                    },
                    {
                        internalType: "address",
                        name: "staker",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "startDate",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "maturationDate",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "dateWithdrawn",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "totalRewardClaimed",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "stakeBurnt",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "stakingFee",
                        type: "uint256",
                    },
                ],
                internalType: "struct PerpetualStaking.Stake",
                name: "",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getStakeCount",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "index", type: "uint256" }],
        name: "getTimeRemaining",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "maturationPeriod",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "owner",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "paused",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "investor", type: "address" },
            { internalType: "uint256", name: "index", type: "uint256" },
        ],
        name: "previewInvestorClaimReward",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "investor", type: "address" },
            { internalType: "uint256", name: "index", type: "uint256" },
        ],
        name: "previewInvestorWithdrawStake",
        outputs: [
            { internalType: "uint256", name: "", type: "uint256" },
            { internalType: "uint256", name: "", type: "uint256" },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "investor", type: "address" },
            { internalType: "uint256", name: "index", type: "uint256" },
        ],
        name: "refundInvestorStake",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "renounceOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "rewardRate",
        outputs: [{ internalType: "uint32", name: "", type: "uint32" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "bool", name: "value", type: "bool" }],
        name: "setPaused",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "stakingFeeRate",
        outputs: [{ internalType: "uint32", name: "", type: "uint32" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "startingBurnRate",
        outputs: [{ internalType: "uint32", name: "", type: "uint32" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "token",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "newOwner", type: "address" },
        ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "index", type: "uint256" }],
        name: "withdrawStake",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
];

export default privateStakeAbi;
