import { ethers } from "ethers";
import { NETWORK } from "../config";
import getChain from "./getChain";
import perpetualStakingAbi from "./perpetual-staking-abi";
import privateStakeAbi from "./private-stake-abi";
import stakingAbi from "./stakingAbi";
import tokenAbi from "./tokenAbi";

function connectToContracts(signer) {
    const selectedChain = getChain()[NETWORK];

    if (!selectedChain.tokenContractAddress) {
        throw new Error("Token contract address not set");
    }
    const tokenContract = new ethers.Contract(
        selectedChain.tokenContractAddress,
        tokenAbi,
        signer
    );
    if (!selectedChain.stakingContractAddress) {
        throw new Error("Staking contract address not set");
    }

    const stakingContract = new ethers.Contract(
        selectedChain.stakingContractAddress,
        stakingAbi,
        signer
    );

    const stakingContract_35 = new ethers.Contract(
        selectedChain.stakingContractAddress_35_apy,
        stakingAbi,
        signer
    );

    const stakingContract_new = new ethers.Contract(
        selectedChain.stakingContractAddress_new_apy,
        stakingAbi,
        signer
    );
    const stakingContractSelfMaturing = new ethers.Contract(
        selectedChain.stakingContract_selfMaturing_apy,
        perpetualStakingAbi,
        signer
    );

    const stakingContractSelfMaturingPrivate = new ethers.Contract(
        selectedChain.stakingContract_selfMaturing_apy_private,
        privateStakeAbi,
        signer
    );

    return [
        tokenContract,
        stakingContract,
        stakingContract_35,
        stakingContract_new,
        stakingContractSelfMaturing,
        stakingContractSelfMaturingPrivate,
    ];
}

export default connectToContracts;
