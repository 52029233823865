export const USE_CONTRACT = process.env.REACT_APP_CONTRACT;
export const CONTRACT_35_APY = process.env.REACT_APP_CONTRACT_35_APY;
export const CONTRACT_NEW_APY = process.env.REACT_APP_CONTRACT_NEW_APY;
export const CONTRACT_SELF_MATURING_APY =
    process.env.REACT_APP_CONTRACT_SELF_MATURING_APY;

export const CONTRACT_SELF_MATURING_APY_PRIVATE =
    process.env.REACT_APP_CONTRACT_SELF_MATURING_APY_PRIVATE;

export const USE_BASE_URL = process.env.REACT_APP_NAV_URL;
export const NETWORK = process.env.REACT_APP_NETWORK;
export const PRIVATE_PRODUCT_IDS = process.env.REACT_APP_PRIVATE_PRODUCT_IDS;
export const PRIVATE_LOGIN_ORIGIN = process.env.REACT_APP_PRIVATE_LOGIN_ORIGIN;
export const PRIVATE_LOGIN_FRONTEND_ORIGIN =
    process.env.REACT_APP_PRIVATE_FRONTEND_ORIGIN;
