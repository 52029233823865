import { createContext, useContext, useReducer } from "react";

const context = createContext();

const INIT = "INIT";

export const init = (payload) => ({ payload, type: INIT });

const initialState = {
  signerAddress: "",
  tokenContract: "",
  stackingContract: "",
  timeRemaining: "",
  timeRemaining_35: "",
  stakingContract_35: "",
  timeRemaining_new: "",
  stakingContract_new: "",
  timeRemaining_selfMaturing: "",
  stakingContract_selfMaturing: "",
  timeRemaining_privateStake: "",
  privateStake: "",
};

const reducer = (state, { payload, type }) => {
  switch (type) {
    case INIT: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const MetaMask = ({ children }) => {
  const [data, dispatch] = useReducer(reducer, initialState);

  return (
    <context.Provider value={[data, dispatch]}>{children}</context.Provider>
  );
};

export const useMetaMask = () => useContext(context);

export default MetaMask;
