const extractTimeRemaining = async (stakingContract) => {
  try {
    const timeLeft = await stakingContract.getTimeRemaining();
    return timeLeft.toNumber();
  } catch (err) {
    return 0;
  }
};

export default extractTimeRemaining;
